<template>
  <div class="registration">
    <form @submit.prevent="registration">
      <p class="registration__header">Регистрация пользователя</p>
      <p class="registration__title">Фамилия*</p>
      <input v-model.trim="form.lastName" class="input registration__input" type="text" required placeholder="Введите фамилию">

      <p class="registration__title">Имя*</p>
      <input v-model.trim="form.firstName" class="input registration__input" type="text" placeholder="Введите имя" required>

      <p class="registration__title">Отчество</p>
      <input v-model.trim="form.patronymic" class="input registration__input" type="text" placeholder="Введите отчество">

      <p class="registration__title">Email*</p>
      <input v-model.trim="form.email" class="input registration__input" type="text" placeholder="Введите email" required>

      <p class="registration__title">Телефон</p>
      <input v-model.trim="form.phone" class="input registration__input" type="text" placeholder="Введите номер телефона">

      <p class="registration__title">Пароль*</p>
      <div class="registration__input-password">
        <input v-model.trim="form.password" class="input registration__input" :type="!isPasswordOpen? 'password' : 'text'" placeholder="Введите пароль" required>
        <svg @click="isPasswordOpen = !isPasswordOpen" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.8824 5.23578C15.652 1.86045 12.6571 0 9.44934 0C6.24155 0 3.24669 1.86045 1.01624 5.23578C0.859169 5.47314 0.859169 5.7838 1.01624 6.02115C3.24669 9.39649 6.24155 11.2569 9.44934 11.2569C12.6571 11.2569 15.652 9.39649 17.8824 6.02115C18.0395 5.7838 18.0395 5.47663 17.8824 5.23578ZM9.44934 9.8328C6.85938 9.8328 4.39507 8.34584 2.47179 5.63021C4.39158 2.91459 6.85589 1.42762 9.44934 1.42762C12.0393 1.42762 14.5036 2.91459 16.4269 5.63021C14.5036 8.34584 12.0428 9.8328 9.44934 9.8328Z" fill="#246CB7"/>
          <path d="M9.44958 1.87793C7.3797 1.87793 5.69727 3.56036 5.69727 5.63024C5.69727 7.70012 7.3797 9.38255 9.44958 9.38255C11.5195 9.38255 13.2019 7.70012 13.2019 5.63024C13.2019 3.56036 11.5195 1.87793 9.44958 1.87793ZM9.44958 8.0038C8.16506 8.0038 7.11791 6.93919 7.11791 5.63024C7.11791 4.3213 8.16506 3.25669 9.44958 3.25669C10.7341 3.25669 11.7812 4.3213 11.7812 5.63024C11.7812 6.93919 10.7341 8.0038 9.44958 8.0038Z" fill="#246CB7"/>
          <rect width="1.79656" height="19.3653" transform="matrix(-0.5 0.866025 0.866025 0.5 0.898438 0)" fill="#246CB7"/>
        </svg>
      </div>
      <!-- <div class="registration__input-password">
        <input v-model.trim="form.password" class="input registration__input" type="password" placeholder="Повторите пароль" required>
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.8824 5.23578C15.652 1.86045 12.6571 0 9.44934 0C6.24155 0 3.24669 1.86045 1.01624 5.23578C0.859169 5.47314 0.859169 5.7838 1.01624 6.02115C3.24669 9.39649 6.24155 11.2569 9.44934 11.2569C12.6571 11.2569 15.652 9.39649 17.8824 6.02115C18.0395 5.7838 18.0395 5.47663 17.8824 5.23578ZM9.44934 9.8328C6.85938 9.8328 4.39507 8.34584 2.47179 5.63021C4.39158 2.91459 6.85589 1.42762 9.44934 1.42762C12.0393 1.42762 14.5036 2.91459 16.4269 5.63021C14.5036 8.34584 12.0428 9.8328 9.44934 9.8328Z" fill="#246CB7"/>
          <path d="M9.44958 1.87793C7.3797 1.87793 5.69727 3.56036 5.69727 5.63024C5.69727 7.70012 7.3797 9.38255 9.44958 9.38255C11.5195 9.38255 13.2019 7.70012 13.2019 5.63024C13.2019 3.56036 11.5195 1.87793 9.44958 1.87793ZM9.44958 8.0038C8.16506 8.0038 7.11791 6.93919 7.11791 5.63024C7.11791 4.3213 8.16506 3.25669 9.44958 3.25669C10.7341 3.25669 11.7812 4.3213 11.7812 5.63024C11.7812 6.93919 10.7341 8.0038 9.44958 8.0038Z" fill="#246CB7"/>
          <rect width="1.79656" height="19.3653" transform="matrix(-0.5 0.866025 0.866025 0.5 0.898438 0)" fill="#246CB7"/>
        </svg>
      </div> -->
      <button class="btn registration__button" type="submit" :disabled="isLoading">Отправить</button>
    </form>

  </div>
</template>

<script>
export default {
  name: 'Registration',
  data () {
    return {
      isLoading: false,
      confirmPassword: '',
      form: {
        firstName: '',
        lastName: '',
        patronymic: '',
        email: '',
        phone: '',
        password: ''
      },
      isPasswordOpen: false
    }
  },
  methods: {
    registration () {
      const formData = new FormData()
      const url = `${this.$store.getters.getUrlApi}api/auth/registration`
      this.isLoading = true

      for (const key in this.form) {
        if (this.form[key]) formData.append(key, this.form[key])
      }

      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(async response => {
          if (response.status >= 200 && response.status < 300) {
            this.$noty.success('Вы успешно зарегистрировались. Письмо с дальнейшими действиями отправлено на вашу электронную почту')
            this.$router.push({ path: '/' })
          } else {
            const body = await response.json()
            const error = new Error(body.error ?? 'Ошибка регистрации')
            // error.response = response
            throw error
          }
          this.isLoading = false
        })
        .catch((e) => {
          console.log(e)
          this.$noty.error(e.message)
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.registration__button:disabled {
  opacity: .3;
}

  .registration {
    position: relative;
    padding: 1.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 3px;
    width: 90%;
    max-width: 30rem;
    margin: 2rem auto;

    &__header {
      font-weight: 600;
      font-size: 1rem;
      line-height: 137%;
      color: #1F3245;
      padding-bottom: 0.625rem;
      margin-bottom: 1.25rem;
      border-bottom: 1px solid #EEF4FA;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      a {
        font-size: 0.75rem;
        line-height: 167%;
        color: #246CB7;
      }
    }
    &__title {
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 150%;
      color: #1F3245;
      margin: 0.75rem 0;
    }
    &__input {
      background: #EEF4FA;
      border-radius: 3px;
    }
    &__input-password {
      position: relative;
      svg {
        position: absolute;
        width: 1.1rem;
        right: 1rem;
        top: 0;
        height: 100%;
        cursor: pointer;
      }
    }
    &__button {
      border: none;
      padding: 0.625rem 1.625rem;
      line-height: 143%;
      background: #246CB7;
      border-radius: 3px;
      color: #FFFFFF;
      margin: 1.5rem 0 1.25rem;
    }
    &__button-close {
      position: absolute;
      top: -1rem;
      right: -1rem;
      cursor: pointer;
    }

  }
  @media screen and (max-width: 768px){

  }
  @media screen and (max-width: 420px){

  }
</style>
